<template>
  <div
    class="footer-placeholder"
    :style="{
      '--height': `${isFixed ? height : 0}px`,
    }"
  ></div>
  <footer
    ref="footer"
    class="footer-main"
    :class="{
      '--fixed': height > 0 && isFixed,
    }"
  >
    <div class="footer-main__wrap row-1">
      <div class="footer-main__row">
        <!-- Logo -->
        <NuxtLink :to="localePath({ name: 'index' })" class="footer-main__logo">
          <img
            src="~/assets/img/ico/logo-zava-white.svg"
            width="145"
            height="30"
            :alt="runtimeConfig.public.siteName"
          />
        </NuxtLink>
      </div>

      <div class="footer-main__row footer-main__menu">
        <div class="footer-main__col footer-main__menu__info">
          <!-- Footer addresses -->
          <div
            class="text-m footer-main__menu__address"
            v-for="address in footerAddresses"
          >
            <h3 class="text-xs text-uppercase">
              {{ address.companyAddressType }}
            </h3>
            <div v-html="address.companyAddressContent"></div>
          </div>
        </div>

        <div class="footer-main__col footer-main__menu__navigation">
          <!-- Footer menu -->
          <ul
            class="footer-main__menu__links"
            v-for="(links, index) in linksFooter"
            :key="index"
          >
            <li v-for="link in links" :key="link.key" :class="link.class">
              <!-- Internal Link -->
              <NuxtLink
                v-if="link.route"
                class="link-underline-1"
                :to="localePath(link.route)"
                ><span>{{ $t(`navigation.${link.key}`) }}</span></NuxtLink
              >
              <!-- External Link -->
              <a v-if="link.href" :href="link.href" class="link-underline-1"
                ><span>{{ $t(`navigation.${link.key}`) }}</span></a
              >
            </li>
          </ul>

          <!-- Footer Banner -->
          <nuxt-link
            to="https://zava.frb.io/assets-news/zava-sileo.pdf"
            class="footer-main__menu__banner"
            target="_blank"
          >
            <img
              src="/assets/img/banner-sileo.webp"
              alt="Banner Sileo"
              width="400"
              height="400"
            />
          </nuxt-link>
        </div>
      </div>

      <div class="footer-main__row footer-main__social">
        <!-- Footer menu -->
        <ul class="footer-main__social__links">
          <li v-for="link in linksSocial" :key="link.key" :class="link.class">
            <!-- External Link -->
            <a v-if="link.href" :href="link.href" class="link-underline-1"
              ><span>{{ $t(`navigation.${link.key}`) }}</span></a
            >
          </li>
        </ul>
      </div>

      <div class="footer-main__row footer-main__menu__closure text-s">
        <p>{{ runtimeConfig.public.siteName }} S.r.l.</p>
        <p>{{ $t('footer.vat') }}</p>
        <p>
          <a href="https://www.quamm.it" class="link-underline-1"
            ><span>Quamm.it</span></a
          >
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'FooterMain',
}
</script>

<script setup>
import { debounce } from '~/assets/js/utils.js'

import { useCraftStore } from '@/store/craft.js'

import { navFooter } from '@/bucket/navigations.js'

const { configuration } = useCraftStore()
const runtimeConfig = useRuntimeConfig()

const linksFooter = [navFooter().linksFirstRow, navFooter().linksSecondRow]
const linksSocial = navFooter().linksSocialRow

const footer = ref(null)
const height = ref(0)
const isFixed = ref(false)

const footerAddresses = computed(() => {
  return configuration.meta.companyAddresses.filter(
    (address) => address.companyAddressMeta !== 'showroom'
  )
})

onMounted(() => {
  window.addEventListener('resize', debounce(onResize))
  onResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', debounce(onResize), false)
})

const onResize = () => {
  if (footer.value) {
    height.value = footer.value.offsetHeight
    isFixed.value = footer.value.offsetHeight < window.innerHeight
  }
}
</script>

<style lang="scss">
@import './style.scss';
</style>
